declare interface ICan {
    deactivateUser: boolean;
    chat: boolean;
    define_sections: boolean;
    change_calendar: boolean;
    switch_terminal: boolean;
    set_next_day_jobs: boolean;
    set_second_jobs: boolean;
    change_second_jobs_priorities: boolean;
    change_terminal_settings: boolean;
    notify_new_version: boolean;
    manageScheduler: boolean;
    manageRedis: boolean;
    viewDispo: boolean;
}

export function useCan(): ICan {
    const { data: authData } = useAuth();
    const rights = authData.value?.rights || [];
    const returnObject = {
        deactivateUser:
            rights.includes("setup:sections") || rights.includes("setup:read") || rights.includes("dispatcher"),
        chat: rights.includes("message"),
        define_sections: rights.includes("setup:sections"),
        change_calendar: rights.includes("dispatcher"),
        switch_terminal: rights.includes("setup:sections"),
        set_next_day_jobs: rights.includes("setup:sections") || rights.includes("setup:read"),
        set_second_jobs: rights.includes("setup:sections"),
        change_second_jobs_priorities: rights.includes("setup:sections"),
        change_terminal_settings: rights.includes("setup:sections") || rights.includes("setup:read"),
        notify_new_version: rights.includes("admin"),
        manageScheduler: rights.includes("admin"),
        manageRedis: rights.includes("admin"),
        viewDispo: rights.includes("dispatcher")
    };
    return returnObject;
}
